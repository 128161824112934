import { getItem } from '../../../../api/api-methods';
import {
  fetchGroups,
  fetchSchoolCycleByEI,
} from '../../../configuration/helpers/KnGroupsOptions';
import { fetchSyllabus } from '../../../employees/helpers/syllabusOptions';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTable from '../../../shared/components/KnTable.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import { mapState } from 'vuex';
import { getFullName } from '../../../shared/helpers/dataUtils';
import { fetchStudentByName } from '../../../students/helpers/utilsStudent';
import { fetchStudents } from '../../../employees/helpers/reportCardOptions';
import {
  canAdd,
  canChange,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
import {
  fetchDebits,
  postBatchTuitionAllocationAll,
  postBatchTuitionAllocationIndividual,
  postBatchTuitionUpdateAll,
  postBatchTuitionUpdateIndividual,
} from '../../helpers/knDebitsOptions';
export default {
  name: 'KnFormTuitionAllocation',
  components: {
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormSubtitle,
    KnFormTitle,
    KnLocalAlert,
    KnPagination,
    KnSelect,
    KnTextField,
    KnTable,
  },
  mixins: [validationFormMixin, paginationMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tuitions: [],
      tution: null,
      penalties: [],
      penalty: null,
      discounts: [],
      discount: null,
      schoolCycle: [],
      selectedSchoolCycle: null,
      syllabus: [],
      educationlevels: [],
      groups: [],
      students: [],
      selectedStudents: [],
      loadingStudents: false,
      inputStudent: null,
      educationlevelById: null,
      schoolCycleById: null,
      prueba: '',
      routerName: 'Adeudos',
      resource: 'adeudo',
      bkEntity: null,
      bkReadonly: false,
      ex11: false,
      firstDate: null,
      endDate: null,
      applyAll: true,
      batchAllocationInfo: {},
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
      schoolLevelFilter: null,
      schoolCycleFilter: null,
      groupFilter: null,
      detalle_alumnos: [],
      debtInfo: {
        id_alumno: null, // Para cuando sea aplicar individual
        id_penalizacion: null,
        id_descuento: null,
        id_colegiatura: null,
        dias_gracias: 5,
        fecha_inicio_ciclo: null,
        fecha_fin_ciclo: null,
        descuento_aplicado: false,
        ids_alumno: [], // Para cuando sea aplicar a todos
        selected_cycle: null,
      },
      initialDebitsCheck: false,
      wildcardStudent: null,
      wildcardDebits: [],
      wildcardErrors: [],
    };
  },
  computed: {
    ...mapState(['institutionId']),
    isNewMode() {
      return this.entity === null || this.bkEntity === null;
    },
    title() {
      return this.isNewMode
        ? 'Asignación de colegiaturas'
        : 'Actualización de colegiaturas';
    },
    isReadonly() {
      return this.readonly || this.bkReadonly;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Colegiaturas asignadas exitosamente'
        : 'Colegiaturas actualizadas exitosamente';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    warningText() {
      return this.warnings.length ? this.warnings.join(',') : null;
    },
    applyAllRules() {
      return this.applyAll ? [this.rules.required] : [];
    },
    filteredAvailableStudents() {
      return this.students.filter(
        (aStudent) =>
          !this.selectedStudents
            .map((sStudent) => sStudent.id)
            .includes(aStudent.id)
      );
    },
    selectedSchoolLevelName() {
      let schoolLevelName = null;
      if (this.schoolCycleFilter) {
        const findedLevel = this.educationlevels.find(
          (l) => l.id === this.schoolCycleFilter
        );
        if (findedLevel) {
          schoolLevelName = findedLevel.nombre;
        }
      }
      return schoolLevelName;
    },
    groupFilterObj() {
      return this.groupFilter
        ? this.groups.find((g) => g.id === this.groupFilter)
        : null;
    },
  },
  watch: {
    inputStudent: {
      async handler() {
        await this.searchStudent(1);
      },
    },
    applyAll(value) {
      if (!value) {
        this.cleanSettings();
      }
    },
  },
  async created() {
    // await this.getPaginatedGroups(1);
    this.setPaginationLimit(30);
    await this.fetchData();
    await this.searchStudent(1);
  },
  methods: {
    getFullName: getFullName,
    async fetchData() {
      //Ciclo escolar
      const resSchoolCycles = await fetchSchoolCycleByEI(this.institutionId);
      this.schoolCycle = resSchoolCycles.results ? resSchoolCycles.results : [];
      //Plan de estudios
      const { ok, data } = await fetchSyllabus({
        institutionId: this.institutionId,
        systemStatus: true,
      });
      this.syllabus = ok ? data : [];
      //Nivel educativo
      const educationlevelsRes = await getItem(
        `/app-personas/filters/nivel-educativo?limit=15&estatus_sistema=${true}&institucion_educativa=${
          this.institutionId
        }`
      );
      this.educationlevels = educationlevelsRes.results;
      // Colegiatura
      const tuitionData = await getItem(
        `/app-personas/filters/colegiatura?limit=15&estatus_sistema=${true}&institucion_educativa=${
          this.institutionId
        }`
      );
      this.tuitions = tuitionData.results;
      //Penalizaciones
      const penaltyData = await getItem(
        `/app-administracion/filters/penalizacion?limit=15&estatus_sistema=${true}&institucion_educativa=${
          this.institutionId
        }`
      );
      this.penalties = penaltyData.results;
      //Descuentos
      const discountsData = await getItem(
        `/app-administracion/filters/descuento-pronto-pago?limit=15&estatus_sistema=${true}&institucion_educativa=${
          this.institutionId
        }`
      );
      this.discounts = discountsData.results;
    },
    async getPaginatedGroups() {
      try {
        this.groups = [];

        if (!this.loading) {
          const { ok, data, message } = await fetchGroups({
            institutionId: this.institutionId,
            systemStatus: true,
            ciclo_escolar:
              this.schoolCycleFilter !== null ? this.schoolCycleFilter : null,
            nivel_educativo:
              this.schoolLevelFilter !== null ? this.schoolLevelFilter : null,
            limit: 1000,
          });

          if (ok) {
            this.groups = data;
          } else {
            console.error('No se pudieron obtener los grupos', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los grupos:', error);
        this.loading = false;
      }
    },
    setDates() {
      if (this.selectedSchoolCycle) {
        const selectedSchoolCycleObj = this.schoolCycle.find(
          (cycle) => cycle.id === this.selectedSchoolCycle
        );
        this.firstDate = selectedSchoolCycleObj
          ? selectedSchoolCycleObj.fecha_inicio
          : null;
        this.endDate = selectedSchoolCycleObj
          ? selectedSchoolCycleObj.fecha_fin
          : null;
      } else {
        this.firstDate = null;
        this.endDate = null;
      }
    },
    setDatesArray(selectedCycleId, index) {
      if (selectedCycleId) {
        const selectedSchoolCycleObj = this.schoolCycle.find(
          (cycle) => cycle.id === selectedCycleId
        );
        this.selectedStudents[index].fecha_inicio_ciclo = selectedSchoolCycleObj
          ? selectedSchoolCycleObj.fecha_inicio
          : null;
        this.selectedStudents[index].fecha_fin_ciclo = selectedSchoolCycleObj
          ? selectedSchoolCycleObj.fecha_fin
          : null;
      } else {
        this.selectedStudents[index].fecha_inicio_ciclo = null;
        this.selectedStudents[index].fecha_fin_ciclo = null;
      }
    },
    async setGroups() {
      await this.getPaginatedGroups();
      if (!this.schoolCycleFilter) {
        this.searchStudent(1);
      }
    },
    async searchStudent(page = 1) {
      try {
        // console.log('searchStudent page', page);

        if (!this.loadingStudents) {
          this.loadingStudents = true;
          this.students = [];
          this.setPaginationPage(page);
          if (this.inputStudent && this.inputStudent !== '') {
            const { ok, data } = await fetchStudentByName(
              this.inputStudent,
              this.pagination,
              true,
              this.institutionId,
              this.selectedSchoolLevelName
            );
            if (ok) {
              this.students = data.results;
              this.setPaginationCount(data.results.length);
            } else {
              this.setPaginationCount(0);
            }
          } else {
            const {
              ok: okStudent,
              data: dataStudent,
              count: countStudent,
            } = await fetchStudents({
              institutionId: this.institutionId,
              systemStatus: true,
              schoolLevelId: this.schoolLevelFilter,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });
            this.students = okStudent ? dataStudent : [];
            this.setPaginationCount(countStudent);
          }
        }
      } catch (error) {
        console.log('Error al intentar obtener alumnos', error);
      } finally {
        this.loadingStudents = false;
      }
    },
    async getWildcardStudent() {
      try {
        // console.log('dentro getWildcardStudent');

        const { ok, data } = await fetchStudentByName(
          'Comodin',
          undefined,
          true,
          this.institutionId
        );
        if (ok) {
          const wildcardStudents = data.results;
          if (wildcardStudents.length) {
            this.wildcardStudent = wildcardStudents[0];
          }
        } else {
          this.wildcardStudent = null;
          this.wildcardErrors.push(
            'No se encontró alumno comodín. Asegurate de que exista y que tenga los adeudos necesarios.'
          );
        }
      } catch (error) {
        this.wildcardStudent = null;
        this.wildcardErrors.push('Error al intentar obtener alumno comodín');
      }
    },
    async getWildcardInfo() {
      if (this.initialDebitsCheck) {
        await this.getWildcardStudent();
        if (!this.wildcardStudent) {
          this.initialDebitsCheck = false;
        }
      }
    },
    async getWildcardDebits() {
      try {
        const { ok, data } = await fetchDebits({
          alumno: this.wildcardStudent.id,
          systemStatus: true,
          institutionId: this.institutionId,
        });
        if (ok) {
          this.wildcardDebits = data;
        } else {
          this.wildcardDebits = [];
          this.wildcardErrors.push('No se encontraron adeudos iniciales');
        }
      } catch (error) {
        this.wildcardErrors.push('Error al intentar obtener adeudos iniciales');
      }
    },
    async setStudentsPerGroup() {
      if (this.groupFilterObj) {
        this.students = this.groupFilterObj.alumnos;
        this.setPaginationLimit(this.students.length);
        this.setPaginationCount(this.students.length);
        this.selectedStudents = [];
      } else {
        await this.searchStudent(1);
      }
    },
    addStudent(item) {
      const detailItem = this.applyAll ? item : { ...item, ...this.debtInfo };
      this.selectedStudents.push(detailItem);
    },
    removeStudent(item) {
      this.selectedStudents = this.selectedStudents.filter(
        (sStudent) => sStudent.id !== item.id
      );
    },
    completeAlert() {
      this.loading = false;
      if (this.errors.length) {
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = this.successAlertType;
        this.alertColor = 'success';
        this.alertText = this.successAlertText;
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      this.selectedStudents = [];
      this.cleanFilters();
      this.cleanSettings();
      // this.schoolLevelFilter = null;
      // this.schoolCycleFilter = null;
      // this.groupFilter = null;
      // this.tution = null;
      // this.penalty = null;
      // this.discount = null;
      // this.selectedSchoolCycle = null;
      // this.firstDate = null;
      // this.endDate = null;
      // this.inputStudent = null;
    },
    cleanSettings() {
      this.tution = null;
      this.penalty = null;
      this.discount = null;
      this.selectedSchoolCycle = null;
      this.firstDate = null;
      this.endDate = null;
    },
    cleanFilters() {
      this.schoolLevelFilter = null;
      this.schoolCycleFilter = null;
      this.groupFilter = null;
      this.inputStudent = null;
    },
    async save() {
      this.loading = true;
      this.alertText = 'Cargando...';
      this.showAlert = true;

      const debitInfo = this.buildAssignmentDetails();
      // console.log('save debitInfo ', debitInfo);

      if (this.isNewMode) {
        if (canAdd(this.resource)) {
          await this.batchTuitionAllocation(debitInfo);
        } else {
          this.insufficientPermissionAlert();
        }
      } else {
        if (canChange(this.resource)) {
          await this.batchTuitionUpdate(debitInfo);
        } else {
          this.insufficientPermissionAlert();
        }
      }
      this.completeAlert();
    },
    buildAssignmentDetails() {
      const dataToSend = this.applyAll
        ? {
            ids_alumno: this.selectedStudents.map((ss) => ss.id),
            id_penalizacion: this.penalty,
            id_descuento: this.discount,
            id_colegiatura: this.tution,
            dias_gracia: 5,
            fecha_inicio_ciclo: this.firstDate,
            fecha_fin_ciclo: this.endDate,
            descuento_aplicado: this.discount ? true : false,
            copiar_adeudos_comodin: this.initialDebitsCheck,
            id_alumno_comodin: this.initialDebitsCheck
              ? this.wildcardStudent
                ? this.wildcardStudent.id
                : null
              : null,
          }
        : {
            copiar_adeudos_comodin: this.initialDebitsCheck,
            id_alumno_comodin: this.initialDebitsCheck
              ? this.wildcardStudent
                ? this.wildcardStudent.id
                : null
              : null,
            detalle_alumnos: this.selectedStudents.map((ss) => {
              const item = {
                id_alumno: ss.id,
                id_penalizacion: ss.id_penalizacion,
                id_descuento: ss.id_descuento,
                id_colegiatura: ss.id_colegiatura,
                dias_gracia: 5,
                fecha_inicio_ciclo: ss.fecha_inicio_ciclo,
                fecha_fin_ciclo: ss.fecha_fin_ciclo,
                descuento_aplicado: ss.id_descuento ? true : false,
              };
              return item;
            }),
          };
      return dataToSend;
    },
    async batchTuitionAllocation(debitInfo) {
      try {
        if (this.applyAll) {
          const { ok, message } = await postBatchTuitionAllocationAll(
            debitInfo
          );
          if (!ok) {
            this.errors.push(message);
          }
        } else {
          const { ok, message } = await postBatchTuitionAllocationIndividual(
            debitInfo
          );
          if (!ok) {
            this.errors.push(message);
          }
        }
      } catch (error) {
        this.errors.push(error);
      }
    },
    async batchTuitionUpdate(debitInfo) {
      try {
        if (this.applyAll) {
          const { ok, message } = await postBatchTuitionUpdateAll(debitInfo);
          if (!ok) {
            this.errors.push(message);
          }
        } else {
          const { ok, message } = await postBatchTuitionUpdateIndividual(
            debitInfo
          );
          if (!ok) {
            this.errors.push(message);
          }
        }
      } catch (error) {
        this.errors.push(error);
      }
    },
    async batchTuitionUnassignment() {
      try {
        ////
      } catch (error) {
        this.errors.push(error);
      }
    },
  },
};
