import { mapState } from 'vuex';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import { getItem, putItem } from '../../../../api/api-methods';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import { getFullName } from '../../../shared/helpers/dataUtils';

export default {
  name: 'KnTuitionAllocation',
  components: {
    KnFormTitle,
    KnSelect,
    KnTextField,
    KnFormActionButtons,
  },
  mixins: [validationFormMixin],
  data() {
    return {
      loading: false,
      routerName: 'Adeudos',
      resource: 'adeudo',
      valueDeterminate: 100,
      conins: [],
      penaltys: [],
      discounts: [],
      debits: [],
      debitId: [],
      completeName: null,
      debit: {
        id: 'NA', // No asignado,
        alumno: null,
        comentarios: '',
        sub_total: 0.0,
        total_impuestos: 0.0,
        total_descuento: 0.0,
        total_adeudo: 0.0,
        descuento_aplicado: 0.0,
        moneda: null,
        expirado: false,
        fecha_vencimiento: '',
        fecha_pronto_pago: '',
        penalizacion: null,
        descuento_pronto_pago: null,
        institucion_educativa: null,
        estatus: null,
        autor: null,
        nombre_adeudo: '',
      },
      errors: [],
      showAlert: false,
      alertType: 'success',
      alertColor: null,
      loadingAlert: false,
      alertText: 'Actualización exitosa',
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    successAlertText() {
      return 'Adeudo creado';
    },
    successAlertType() {
      return 'success';
    },
  },
  async created() {
    this.loadingPage = true;
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      const paramId = this.$route.params.id;
      if (paramId) {
        this.debitId = this.validateId(paramId);
        this.fetchData();
        this.fillData();
        this.debit.institucion_educativa = this.institutionId;
      } else return undefined;
    } catch (error) {
      console.error('Error', error);
    } finally {
      this.loadingPage = false;
      this.setLoadingState(false);
    }
  },
  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loadingAlert = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loadingAlert = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loadingAlert = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = this.successAlertType;
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion
    async fetchData() {
      //Peticiones get
      const coinData = await getItem(
        `/app-administracion/filters/moneda?institucion_educativa=${this.institutionId}&estatus_sistema=true`
      );
      const penaltyData = await getItem(
        `/app-administracion/filters/penalizacion?limit=15&estatus_sistema=${true}&institucion_educativa=${
          this.institutionId
        }`
      );
      const discountData = await getItem(
        `/app-administracion/filters/descuento-pronto-pago?institucion_educativa=${this.institutionId}&estatus_sistema=true`
      );
      const debitStatus = await getItem(
        `/app-administracion/filters/estatus-adeudos?institucion_educativa=${this.institutionId}&estatus_sistema=true`
      );
      //Llenar arrays de los selects
      this.conins = coinData.results;
      this.penaltys = penaltyData.results;
      this.discounts = discountData.results;
      this.debits = debitStatus.results;
    },
    async fetchDebitById(idDebit) {
      try {
        // Fetch the criteria data
        const response = await getItem(
          `/app-administracion/adeudos/${idDebit}`
        );

        // Return the value directly from the resolved promise
        return response || 'Sin datos para mostrar';
      } catch (error) {
        // Log the error for debugging purposes
        console.error('Error al intentar obtener el adeudo:', error);

        // Return a default message in case of an error
        return 'Sin datos';
      }
    },
    async fillData() {
      try {
        const responseData = await this.fetchDebitById(this.debitId);
        if (responseData) {
          this.debit = {
            id: responseData.id || null,
            alumno: responseData.alumno || null,
            comentarios: responseData.comentarios || '',
            sub_total: responseData.sub_total || 0.0,
            total_impuestos: responseData.total_impuestos || 0.0,
            total_descuento: responseData.total_descuento || 0.0,
            total_adeudo: responseData.total_adeudo || 0.0,
            descuento_aplicado: responseData.descuento_aplicado || 0.0,
            moneda: responseData.moneda || null,
            expirado: responseData.expirado || false,
            fecha_vencimiento: responseData.fecha_vencimiento || '',
            fecha_pronto_pago: responseData.fecha_pronto_pago || '',
            penalizacion: responseData.penalizacion || null,
            descuento_pronto_pago: responseData.descuento_pronto_pago || null,
            institucion_educativa: this.institutionId || null,
            estatus: responseData.estatus || null,
            autor: responseData.autor || null,
            nombre_adeudo: responseData.nombre_adeudo || '',
          };
          const personalData = await getItem(
            `/app-personas/datos-personales/${responseData.alumno.datos_personales}`
          );
          if (personalData) {
            this.completeName = getFullName(personalData);
          }
        }
      } catch (error) {
        // Manejo de errores si fetchDebitById falla
        console.error('Error al obtener los datos del débito:', error);
      }
    },
    calculateTotal() {
      let {
        sub_total: subtotal,
        total_impuestos: impuestos,
        total_descuento: descuento,
        total_adeudo: total,
      } = this.debit;

      impuestos = subtotal * 0.0;
      total = subtotal + impuestos - descuento;
      this.debit.total_impuestos = impuestos;
      this.debit.total_adeudo = total;
    },
    async save() {
      this.loading = true;
      this.showAlert = true;
      this.alertText = 'Creando adeudo';
      try {
        const debitObj = {
          ...this.debit,
          alumno: this.debit.alumno.id,
          autor: this.userData.id,
          moneda: this.debit.moneda.id,
          estatus: this.debit.estatus.id,
          descuento_pronto_pago:
            this.debit.descuento_pronto_pago !== null &&
            this.debit.descuento_pronto_pago !== undefined
              ? this.debit.descuento_pronto_pago.id
              : this.debit.descuento_pronto_pago,
          penalizacion:
            this.debit.penalizacion !== undefined &&
            this.debit.penalizacion !== null
              ? this.debit.penalizacion.id
              : this.debit.penalizacion,
        };
        
        this.calculateTotal();
        const {ok, data} = await this.putDebit(this.debitId, debitObj);
    
        if (!ok || !data) {
          this.alertType = 'error';
          this.alertText = 'No se pudo crear el cargo, intentelo nuevamente';
        } else {
          this.setSuccessState(true, 'Datos guardados');
        }
      } catch (error) {
        console.error('Ocurrió un error inesperado', error);
        this.alertType = 'error';
        this.alertText = 'Ocurrió un error inesperado, intentelo nuevamente';
      } finally {
        // Desactivar loading y alerta después de la operación
        this.loadingAlert = false;
        
      }
    },
    
    async putDebit(debitId, debitObj) {
      try {
        const {ok, data, message} = await putItem(
          `/app-administracion/adeudos/${debitId}`,
          debitObj
        );
        const responseData = data;
        if (!responseData || !ok) {
          return { ok: false, data: responseData, message };
        } else {
          return { ok: true, data: responseData, message };
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar el adeudo');
        console.error('Error al intentar actualizar el adeudo.', error);
        return { ok: false, data: null };
      }
    },
    cancel() {
      this.returnToTable();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    validateId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = 'Formato de ID no válido.';
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },
  },
};
