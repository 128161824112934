import { getItem, postItem } from '../../../api/api-methods';

export const fetchDebits = async ({
  nombre_adeudo = null,
  alumno = null,
  descuento_aplicado = null,
  moneda = null,
  estatus = null,
  expirado = null,
  fecha_vencimiento = null,
  fecha_pronto_pago = null,
  penalizacion = null,
  descuento_pronto_pago = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter =
      institutionId !== null ? `institucion_educativa=${institutionId}` : '';
    const nameDebitFilter =
      nombre_adeudo !== null ? `&nombre_adeudo=${nombre_adeudo}` : '';
    const studentFilter = alumno !== null ? `&alumno=${alumno}` : '';
    const discountAppliedFilter =
      descuento_aplicado !== null
        ? `&descuento_aplicado=${descuento_aplicado}`
        : '';
    const currencyFilter = moneda !== null ? `&moneda=${moneda}` : '';
    const statusFilter = estatus !== null ? `&estatus=${estatus}` : '';
    const expiredFilter = expirado !== null ? `&expirado=${expirado}` : '';
    const expirationDateFilter =
      fecha_vencimiento !== null
        ? `&fecha_vencimiento=${fecha_vencimiento}`
        : '';
    const promptPaymentDatenFilter =
      fecha_pronto_pago !== null
        ? `&fecha_pronto_pago=${fecha_pronto_pago}`
        : '';
    const penaltyFilter =
      penalizacion !== null ? `&penalizacion=${penalizacion}` : '';
    const cashDiscountFilter =
      descuento_pronto_pago !== null
        ? `&descuento_pronto_pago=${descuento_pronto_pago}`
        : '';

    const authorFilter = autor !== null ? `&autor=${autor}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/adeudos?'.concat(
        institutionFilter,
        nameDebitFilter,
        studentFilter,
        discountAppliedFilter,
        currencyFilter,
        statusFilter,
        expiredFilter,
        expirationDateFilter,
        promptPaymentDatenFilter,
        penaltyFilter,
        cashDiscountFilter,
        authorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los adeudos. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los adeudos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los adeudos. ' + error,
      count: 0,
    };
  }
};

export const fetchDebitStatus = async ({
  dato = null,
  institutionId = null,
  autor = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter =
      institutionId !== null ? `institucion_educativa=${institutionId}` : '';
    const nameFilter =
      dato !== null ? `&dato=${dato}` : '';
    
    const authorFilter = autor !== null ? `&autor=${autor}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/estatus-adeudos?'.concat(
        institutionFilter,
        nameFilter,
        authorFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los estatus de adeudos. ' + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener los estatus de adeudos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener los estatus de adeudos. ' + error,
      count: 0,
    };
  }
};

export const postBatchTuitionAllocationAll = async (
  details = {
    ids_alumno: [],
    id_penalizacion: null,
    id_descuento: null,
    id_colegiatura: null,
    dias_gracia: 5,
    fecha_inicio_ciclo: null,
    fecha_fin_ciclo: null,
    descuento_aplicado: false,
  }
) => {
  try {
    console.log(details);
    const response = await postItem(
      '/app-personas/helpers/asignacion-alumnos-por-lote',
      details
    );

    if (response.e || response.error) {
      return {
        ok: false,
        message:
          'No se pudieron asignar colegiaturas en lote. ' + response.e ||
          response.error,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar asignar colegiaturas en lote. ' + error,
    };
  }
};

export const postBatchTuitionAllocationIndividual = async (
  details = {
    detalle_alumnos: [],
  }
) => {
  try {
    console.log(details);
    const response = await postItem(
      '/app-personas/helpers/asignacion-alumnos-por-lote',
      details
    );

    if (response.e || response.error) {
      return {
        ok: false,
        message:
          'No se pudieron asignar colegiaturas en lote. ' + response.e ||
          response.error,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        'Error al intentar generarar los adeudos de ciclo escolar en lote. ' +
        error,
    };
  }
};

export const postBatchTuitionUpdateAll = async (
  details = {
    ids_alumno: [],
    id_penalizacion: null,
    id_descuento: null,
    id_colegiatura: null,
    dias_gracia: 5,
    fecha_inicio_ciclo: null,
    fecha_fin_ciclo: null,
    descuento_aplicado: false,
  }
) => {
  try {
    console.log(details);
    const response = await postItem(
      '/app-personas/helpers/actualizar-alumnos-colegiatura-por-lote',
      details
    );

    if (response.e || response.error) {
      return {
        ok: false,
        message:
          'No se pudieron asignar colegiaturas en lote. ' + response.e ||
          response.error,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar asignar colegiaturas en lote. ' + error,
    };
  }
};

export const postBatchTuitionUpdateIndividual = async (
  details = {
    detalle_alumnos: [],
  }
) => {
  try {
    console.log(details);
    const response = await postItem(
      '/app-personas/helpers/actualizar-alumnos-colegiatura-por-lote',
      details
    );

    if (response.e || response.error) {
      return {
        ok: false,
        message:
          'No se pudieron actualizar colegiaturas en lote. ' + response.e ||
          response.error,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        'Error al intentar actualizar los adeudos de ciclo escolar en lote. ' +
        error,
    };
  }
};
