import { mapMutations, mapState } from 'vuex';
import { fetchDebits, fetchDebitStatus } from '../../helpers/knDebitsOptions';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import { insufficientPermissionsMessage } from '../../../shared/helpers/permissionsUtils';
import KnTable from '../../../shared/components/KnTable.vue';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import { getFullName } from '../../../shared/helpers/dataUtils';
import { getItem } from '../../../../api/api-methods';

export default {
  name: 'tuitionAllocationIndex',
  components: {
    KnTable,
    KnLocalAlert,
  },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      loading: false,
      showInactive: false,
      debitsArray: [],
      headers: [
        { text: '', value: 'avatar' },
        {
          text: 'Alumno',
          value: 'alumno.nombreCompleto',
          class: 'purple--text',
        },
        { text: 'Adeudo', value: 'nombre_adeudo', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
        {
          text: 'Fecha pronto pago',
          value: 'fecha_pronto_pago',
          class: 'purple--text',
        },
        {
          text: 'Fecha vencimiento',
          value: 'fecha_vencimiento',
          class: 'purple--text',
        },
        { text: 'Monto', value: 'sub_total', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text', sortable: false },
      ],
      /******************* */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /** ***************** */
      debitStatus: [],
      fromFilter: null,
      toFilter: null,
      debitStatusFilter: null,
    };
  },
  computed: {
    ...mapState(['institutionId']),
    ...mapMutations(['setIsLogin']),
    tableTitle() {
      return this.showInactive ? 'Adeudos Inactivos' : 'Adeudos';
    },
    buttomActionText() {
      return this.showInactive
        ? 'Ver adeudos activos'
        : 'Ver adeudos inactivos';
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedDebit(1);
      },
    },
    institutionId: {
      async handler() {
        await this.getPaginatedDebit(1);
      },
    },
    // fromFilter: {
    //   async handler() {
    //     await this.getPaginatedDebit(1);
    //   },
    // },
    toFilter: {
      async handler(value) {
        if (!value) this.fromFilter = null;
        await this.getPaginatedDebit(1);
      },
    },
    debitStatusFilter: {
      async handler() {
        await this.getPaginatedDebit(1);
      },
    },
  },
  async created() {
    await this.fetchData();
    await this.getPaginatedDebit(1);
  },
  methods: {
    //#region peticiones get
    async fetchData() {
      const { ok, data } = await fetchDebitStatus({
        institutionId: this.institutionId,
        systemStatus: true,
        limit: 10,
      });
      if (ok) {
        this.debitStatus = data;
      }
    },
    async getPaginatedDebit(page = 1) {
      try {
        this.debitsArray = [];
        this.setPaginationPage(page);
        if (!this.loading) {
          this.loading = true;
          const { ok, data, message, count } = await fetchDebits({
            institutionId: this.institutionId,
            systemStatus: !this.showInactive,
            fecha_vencimiento: `${this.fromFilter}${
              this.toFilter ? ',' + this.toFilter : ''
            }`,
            estatus: this.debitStatusFilter,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });
          // console.log('Data', data);
          if (ok) {
            this.setPaginationCount(count);
            for (const debit of data) {
              this.debitsArray.push({ ...debit });
              const personalData = await getItem(
                `/app-personas/datos-personales/${debit.alumno.datos_personales}`
              );
              if (personalData) {
                const completeName = getFullName(personalData);
                debit.alumno['nombreCompleto'] = completeName;

                // console.log('Alumno', completeName);
              } else {
                console.warn(
                  'No se encontraron datos personales para el alumno:',
                  debit.alumno.datos_personales
                );
              }
            }
          } else {
            console.error('No se pudieron obtener los grupos', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los grupos:', error);
        this.loading = false;
      }
    },
    //#endregion
    //#region Acciones
    async action2(/*debitId*/) {
      if (this.showInactive) {
        // console.log(debitId);
        //await this.enableAnnouncement(debitId);
      } else {
        //await this.disableAnnouncement(debitId);
      }
    },
    //#endregion
  },
};
